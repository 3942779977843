<template>
  <div class="page-login">
    <div class="login-telephone">
      <label for="telephone">手机号</label>
      <input type="tel" maxlength="11" v-model="telephone" />
    </div>

    <div class="underline"></div>



    <div class="login-code">
      <label for="code">验证码</label>
      <input type="tel" maxlength="6" v-model="verificationCode" />
      <img
        @click="clearCode()"
        class="code-clear"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotTopicsNTo2_HaiLuo/clear.png"
        alt="clear"
      />
      <div class="code-short-line"></div>
      <span
        class="code-tips"
        @click="sendPhoneCode()"
        :disabled="countdown > 0"
        >{{ countdown > 0 ? `${countdown}s后重新发送` : "发送验证码" }}</span
      >
    </div>

    <div class="underline"></div>

    <div class="login-button">
      <button @click="toLogin()">立即登录</button>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      telephone: "",
      verificationCode: "",
      countdown: 0,
    };
  },
  methods: {
    clearCode() {
      this.verificationCode = "";
    },
    // 发送短信验证码
    sendPhoneCode() {
      console.log(this.telephone);
      fetch(
        "https://huanxinxiang.ai-me.cn/huanxinxiang-service/v1/code/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number: this.telephone,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.code == 0) {
            console.log(data);

            // 成功才走倒计时
            this.startCountdown();
          } else {
            alert(data.message);
          }
        })
        .catch((error) => {
          console.error("获取商品信息失败:", error);
        });
    },

    // 倒计时
    startCountdown() {
      this.countdown = 60;
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },

    // 验证码登录
    toLogin() {
      fetch(
        "https://huanxinxiang.ai-me.cn/huanxinxiang-service/v1/login/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number: this.telephone,
            phone_code: this.verificationCode,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.code == 0) {
            // 把token存到本地缓存
            localStorage.setItem("token", data.token);
            // 跳转到权益领取页面
            this.$router.push({ path: "/monthlyHotTopicsNTo2_HaiLuo/receive" });
          } else {
            alert(data.message);
          }
        })
        .catch((error) => {
          console.error("获取商品信息失败:", error);
        });
    },

  },
};
</script>

<style>
.page-login {
  /* width: 100vw;
  height: 100vh; */
  padding: 72px 0 0 24px;
  box-sizing: border-box;
}

/* 手机号begin */
.login-telephone {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* background-color: skyblue; */
  padding: 0px 0 0 24px;
}

label {
  width: 197px;
  height: 48px;
  font-weight: 400;
  font-size: 34px;
  color: #333333;
  line-height: 48px;
  text-align: left;
  font-style: normal;
  white-space: nowrap;
  flex-shrink: 0;
}

.login-telephone input {
  height: 48px;
  border: none;
  /* width: 100%; */
  width: 505px;
  padding: 0;
  /* padding-left: 24px; */
  font-size: 34px;
}

.login-telephone input:focus {
  outline: none;
  border-color: transparent;
}

/* 手机号end */
.underline {
  margin: 23px 0;
  width: 100%;
  height: 1px;
  background-color: #eee;
}

/* 验证码部分start */
.login-code {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* background-color: skyblue; */
  padding: 0px 20px;
  box-sizing: border-box;
}

.code-short-line {
  width: 2px;
  height: 44px;
  background-color: #eee;
  margin: 0 15px;
}

.code-tips {
  width: 203px;
  height: 40px;
  font-weight: 400;
  font-size: 28px;
  color: #cccccc;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  white-space: nowrap;
}

.login-code input {
  height: 48px;
  border: none;
  width: 254px;
  /* padding-left: 24px; */
  font-size: 34px;
}

.login-code input:focus {
  outline: none;
  border-color: transparent;
}

.code-clear {
  width: 30px;
  height: 30px;
}

/* 验证码部分end */

/* 登录按钮部分start */
.login-button {
  margin-top: 64px;
  display: flex;
  justify-content: center;
}

.login-button button {
  width: 650px;
  height: 88px;
  background: linear-gradient(335deg, #ff7c00 0%, #ff5000 100%);
  border-radius: 44px;
  border: none;
  font-size: 32px;
  color: #ffffff;
}

/* 登录按钮部分end */
</style>
